<template>
  <div>
    <base-header class="pb-6" style="background-color:#0bdeea !important;">
    </base-header>

    <b-container fluid class="mt--6">
      <b-row>
        <b-col lg="2" />
        <b-col lg="8">
          <div class="card-wrapper">
            <card>
               <b-row class="mt-3">
                  <b-col md="12" class="text-center">
                    <div class="card-header nBorderB mAuto"><h3 class="mb-0">Alta de gestoría</h3></div>
                  </b-col>
                  <b-col md="12">
                    <base-input label="Tipo de gestor">
                      <el-select v-model="perfilGestor" filterable placeholder="">
                        <el-option v-for="option in selectPerfil" :key="option.bane" :label="option.name"
                          :value="option.value">
                        </el-option>
                      </el-select>
                    </base-input>
                  </b-col>
                  <b-col md="12">
                    <base-input v-model="identificador" :label=" perfilGestor === 'p' ? 'DNI/NIE' : 'NIF'" prepend-icon="fas fa-user"></base-input>
                  </b-col>
                  <b-col md="12" class="text-center">
                    <button @click.prevent="solicitaLogin" class="btn primary">
                      Guardar
                    </button>
                  </b-col>
               </b-row>
            </card>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
  import { Select, Option } from 'element-ui'
  import "flatpickr/dist/flatpickr.css";

  export default {
    name: 'form-components',
    components: {
      [Select.name]: Select,
      [Option.name]: Option,
    },
    data() {
      return {
        selectPerfil: [{name:'Gestor particular', value: 'p'}, {name: 'Gestoría', value: 'g'}],
        perfilGestor: '',
        identificador: ''
      }
    }
  }
</script>
<style scoped>
.W90 {
    width:90%;
}

.mAuto{
    margin:0 auto;
}

.opa25{
    opacity: 0.25;
}

.moduloActivo{
  background: #00e0ec;
}

.moduloNoActivo{
  background: #e5fcfd;
}
</style>