<template>
  <div>
    <base-header class="pb-6" style="background-color:#0bdeea !important;">
    </base-header>
    <b-container fluid class="mt--6">
      <b-row>
        <b-col lg="1" />
        <b-col lg="10">
          <div class="card-wrapper">
            <!-- Input groups -->
            <card>
              <div class="card-header nBorderB"><h3 class="mb-0">Control de usuarios</h3></div>
              <base-input label="Juan">
                <el-select v-model="opcion1" multiple filterable placeholder="">
                  <el-option v-for="(modulo, index) in modulos" :key="index" :label="modulo.titulo"
                    :value="modulo.titulo">
                  </el-option>
                </el-select>
              </base-input>
              <base-input label="Susana">
                <el-select v-model="opcion2" multiple filterable placeholder="">
                  <el-option v-for="(modulo, index) in modulos" :key="index" :label="modulo.titulo"
                    :value="modulo.titulo">
                  </el-option>
                </el-select>
              </base-input>
              <base-input label="Pepe">
                <el-select v-model="opcion3" multiple filterable placeholder="">
                  <el-option v-for="(modulo, index) in modulos" :key="index" :label="modulo.titulo"
                    :value="modulo.titulo">
                  </el-option>
                </el-select>
              </base-input>
              <base-input label="Alicia">
                <el-select v-model="opcion4" multiple filterable placeholder="">
                  <el-option v-for="(modulo, index) in modulos" :key="index" :label="modulo.titulo"
                    :value="modulo.titulo">
                  </el-option>
                </el-select>
              </base-input>
            </card>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { Select, Option } from 'element-ui'
  export default {
    name: 'form-components',
    components: {
      [Select.name]: Select,
      [Option.name]: Option
    },
    data() {
      return {
        modulos: [
          {titulo: 'Dashboards', checked: false, subtitulo: [{titulo: 'Dashboard', checked: false}, {titulo: 'Detallado', checked: false}]},
          {titulo: 'Tickets', checked: false, subtitulo: [{titulo: 'Agregar ticket', checked: false}, {titulo: 'Listado tickets', checked: false}]},
          {titulo: 'IoT Redes sociales', checked: true},
          {titulo: 'Encuestas', checked: false, subtitulo: [{titulo: 'Creación de encuestas', checked: false}, {titulo: 'Listado de encuestas', checked: false}, {titulo: 'Gráfico de encuestas', checked: false}]},
          {titulo: 'Promociones', checked: true, subtitulo: [{titulo: 'Creación de promociones', checked: false}, {titulo: 'Listado de promociones', checked: false}, {titulo: 'Gráfico de promociones', checked: false}]},
          {titulo: 'Facturación', checked: true, subtitulo: [{titulo: 'Agregar factura', checked: false}, {titulo: 'Listado de factura', checked: false}]},
          {titulo: 'IoT Cliente', checked: true},
          {titulo: 'Comercio', checked: false},
          {titulo: 'Control usuarios', checked: true}
        ],
        opcion1: [],
        opcion2: [],
        opcion3: [],
        opcion4: []
      }
    },
    mounted () {
    },
    methods: {
      pulsaModulo(modulo) {
      }
    }
  }
</script>
<style scoped>
.inlineBlock{
  display: inline-block;
}

.nBorderB{
  border-bottom: none;
}
</style>