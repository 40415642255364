<template>
  <div>
    <base-header class="pb-6" style="background-color:#0bdeea !important;">
    </base-header>

    <b-container fluid class="mt--6">
      <b-row>
        <b-col lg="2" />
        <b-col lg="8">
          <div class="card-wrapper">
            <!-- Input groups -->
            <card>
              <!-- Card header -->
               <b-row class="mt-3">
                  <b-col md="12" class="text-center">
                    <div class="card-header nBorderB mAuto"><h3 class="mb-0">Alta de usuario</h3></div>
                  </b-col>
                  <b-col md="12">
                    <base-input v-model="nombre" label="Nombre" prepend-icon="fas fa-user"></base-input>
                  </b-col>
                  <b-col md="12">
                    <base-input v-model="nombre" label="Apellidos" prepend-icon="fas fa-user"></base-input>
                  </b-col>
                  <b-col md="12">
                    <base-input v-model="correo" label="Correo" prepend-icon="fas fa-user"></base-input>
                  </b-col>
                  <b-col md="12">
                    <base-input label="Modulos">
                      <el-select v-model="moduloUsuario" multiple filterable placeholder="">
                        <el-option v-for="(modulo, index) in modulos" :key="index" :label="modulo.titulo"
                          :value="modulo.titulo">
                        </el-option>
                      </el-select>
                    </base-input>
                  </b-col>
                  <b-col md="12" class="text-center">
                    <button @click.prevent="solicitaLogin" class="btn primary">
                      Guardar
                    </button>
                  </b-col>
               </b-row>
            </card>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
  import { Select, Option } from 'element-ui'
  import "flatpickr/dist/flatpickr.css";

  export default {
    name: 'form-components',
    components: {
      [Select.name]: Select,
      [Option.name]: Option,
    },
    data() {
      return {
        nombre: '',
        correo: '',
        modulos: [
          {titulo: 'Dashboards', checked: false, subtitulo: [{titulo: 'Dashboard', checked: false}, {titulo: 'Detallado', checked: false}]},
          {titulo: 'Tickets', checked: false, subtitulo: [{titulo: 'Agregar ticket', checked: false}, {titulo: 'Listado tickets', checked: false}]},
          {titulo: 'IoT Redes sociales', checked: true},
          {titulo: 'Encuestas', checked: false, subtitulo: [{titulo: 'Creación de encuestas', checked: false}, {titulo: 'Listado de encuestas', checked: false}, {titulo: 'Gráfico de encuestas', checked: false}]},
          {titulo: 'Promociones', checked: true, subtitulo: [{titulo: 'Creación de promociones', checked: false}, {titulo: 'Listado de promociones', checked: false}, {titulo: 'Gráfico de promociones', checked: false}]},
          {titulo: 'Facturación', checked: true, subtitulo: [{titulo: 'Agregar factura', checked: false}, {titulo: 'Listado de factura', checked: false}]},
          {titulo: 'IoT Cliente', checked: true},
          {titulo: 'Comercio', checked: false},
          {titulo: 'Control usuarios', checked: true}
        ],
        moduloUsuario: []
      }
    }
  }
</script>
<style scoped>
.W90 {
    width:90%;
}

.mAuto{
    margin:0 auto;
}

.opa25{
    opacity: 0.25;
}

.moduloActivo{
  background: #00e0ec;
}

.moduloNoActivo{
  background: #e5fcfd;
}
</style>