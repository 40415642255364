<template>
  <div>
    <base-header class="pb-6" style="background-color:#0bdeea !important;">
    </base-header>
    <b-container fluid class="mt--6">
      <b-row>
        <b-col lg="1" />
        <b-col lg="10">
          <div class="card-wrapper">
            <card>
              <b-col md="12" class="text-center">
                  <div class="card-header nBorderB mAuto"><h3 class="mb-0">Cambiar contraseña</h3></div>
              </b-col>
              <b-col md="6" class="mAuto">
                <base-input label="Contraseña actual" prepend-icon="fas fa-user"></base-input>
              </b-col>
              <b-col md="6" class="mAuto">
                <base-input label="Nueva contraseña" prepend-icon="fas fa-user"></base-input>
              </b-col>
              <b-col md="6" class="mAuto">
                <base-input label="Repetir nueva contraseña" prepend-icon="fas fa-user"></base-input>
              </b-col>
              <b-col md="12" class="text-center">
                <button @click.prevent="solicitaLogin" class="btn primary">
                    Cambiar
                </button>
              </b-col>
            </card>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { Select, Option } from 'element-ui'
  export default {
    name: 'form-components',
    components: {
      [Select.name]: Select,
      [Option.name]: Option
    },
    data() {
      return {
        modulos: [
          {titulo: 'Dashboards', checked: false, subtitulo: [{titulo: 'Dashboard', checked: false}, {titulo: 'Detallado', checked: false}]},
          {titulo: 'Tickets', checked: false, subtitulo: [{titulo: 'Agregar ticket', checked: false}, {titulo: 'Listado tickets', checked: false}]},
          {titulo: 'IoT Redes sociales', checked: true},
          {titulo: 'Encuestas', checked: false, subtitulo: [{titulo: 'Creación de encuestas', checked: false}, {titulo: 'Listado de encuestas', checked: false}, {titulo: 'Gráfico de encuestas', checked: false}]},
          {titulo: 'Promociones', checked: true, subtitulo: [{titulo: 'Creación de promociones', checked: false}, {titulo: 'Listado de promociones', checked: false}, {titulo: 'Gráfico de promociones', checked: false}]},
          {titulo: 'Facturación', checked: true, subtitulo: [{titulo: 'Agregar factura', checked: false}, {titulo: 'Listado de factura', checked: false}]},
          {titulo: 'IoT Cliente', checked: true},
          {titulo: 'Comercio', checked: false},
          {titulo: 'Control usuarios', checked: true}
        ],
        opcion1: [],
        opcion2: [],
        opcion3: [],
        opcion4: []
      }
    },
    mounted () {
    },
    methods: {
      pulsaModulo(modulo) {
        // console.log(modulo)
      }
    }
  }
</script>
<style scoped>
.inlineBlock{
  display: inline-block;
}

.nBorderB{
  border-bottom: none;
}

.mAuto{
    margin:0 auto;
}
</style>