<template>
  <div>
    <base-header class="pb-6" style="background-color:#0bdeea !important;">
    </base-header>

    <b-container fluid class="mt--6">
      <b-row>
        <b-col lg="12">
          <div class="card-wrapper">
            <card>
               <b-row class="mt-3">
                  <b-col md="4" class="text-center">
                    <div class="accordion" role="tablist">
                        <b-card no-body class="mb-1">
                        <b-card-header header-tag="header" class="p-1" role="tab">
                            <b-button block v-b-toggle.accordion-1 variant="info">Ventas</b-button>
                        </b-card-header>
                        <b-collapse id="accordion-1" visible accordion="my-accordion" role="tabpanel">
                            <b-card-body>
                            <b-card-text><b-button v-b-toggle.collapse-a.collapse-b @click="endpoint = 'GET Traer ventas'">GET Traer ventas</b-button></b-card-text>
                            <b-card-text><b-button v-b-toggle.collapse-a.collapse-b @click="endpoint = 'POST Generar venta'">POST Generar venta</b-button></b-card-text>
                            </b-card-body>
                        </b-collapse>
                        </b-card>

                        <b-card no-body class="mb-1">
                        <b-card-header header-tag="header" class="p-1" role="tab">
                            <b-button block v-b-toggle.accordion-2 variant="info">Accordion 2</b-button>
                        </b-card-header>
                        <b-collapse id="accordion-2" accordion="my-accordion" role="tabpanel">
                            <b-card-body>
                            <b-card-text>{{ text }}</b-card-text>
                            </b-card-body>
                        </b-collapse>
                        </b-card>

                        <b-card no-body class="mb-1">
                        <b-card-header header-tag="header" class="p-1" role="tab">
                            <b-button block v-b-toggle.accordion-3 variant="info">Accordion 3</b-button>
                        </b-card-header>
                        <b-collapse id="accordion-3" accordion="my-accordion" role="tabpanel">
                            <b-card-body>
                            <b-card-text>{{ text }}</b-card-text>
                            </b-card-body>
                        </b-collapse>
                        </b-card>
                    </div>
                  </b-col>
                  <b-col md="4" class="text-center p-0">
                    <b-card no-body class="mb-1">
                    <h3>{{ endpoint }}</h3>
                    <p>
                        Descripción del endpoint
                    </p>
                    <label for="URL">Request URL</label>
                    <input type="text" name="url" :value="url" readonly style="width:90%; margin-left:5%;">
                     <div class="accordion" role="tablist">
                        <b-card no-body class="mb-1">
                        <b-card-header header-tag="header" class="p-1" role="tab">
                            <b-button block v-b-toggle.accordion-1 variant="info">Parametros obligatorios</b-button>
                        </b-card-header>
                        <b-collapse id="accordion-1" visible accordion="my-accordion" role="tabpanel">
                            <b-card-body>
                            <b-card-text><b-button v-b-toggle.collapse-a.collapse-b @click="endpoint = 'GET Traer ventas'">GET Traer ventas</b-button></b-card-text>
                            <b-card-text><b-button v-b-toggle.collapse-a.collapse-b @click="endpoint = 'POST Generar venta'">POST Generar venta</b-button></b-card-text>
                            </b-card-body>
                        </b-collapse>
                        </b-card>

                        <b-card no-body class="mb-1">
                        <b-card-header header-tag="header" class="p-1" role="tab">
                            <b-button block v-b-toggle.accordion-2 variant="info">Parametros opcionales</b-button>
                        </b-card-header>
                        <b-collapse id="accordion-2" accordion="my-accordion" role="tabpanel">
                            <b-card-body>
                            <b-card-text>{{ text }}</b-card-text>
                            </b-card-body>
                        </b-collapse>
                        </b-card>
                    </div>
                    </b-card>
                  </b-col>
                  <b-col md="4" class="text-center">
                
                  </b-col>
               </b-row>
            </card>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
  import { Select, Option } from 'element-ui'
  import "flatpickr/dist/flatpickr.css";

  export default {
    name: 'form-components',
    components: {
      [Select.name]: Select,
      [Option.name]: Option,
    },
    data() {
      return {
        endpoint: '',
        url: 'www.google.com'
      }
    }
  }
</script>
<style scoped>
.W90 {
    width:90%;
}

.mAuto{
    margin:0 auto;
}

.opa25{
    opacity: 0.25;
}

.moduloActivo{
  background: #00e0ec;
}

.moduloNoActivo{
  background: #e5fcfd;
}
</style>