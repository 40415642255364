<template>
  <div>
    <base-header class="pb-6" style="background-color:#0bdeea !important;">
    </base-header>
    <b-container fluid class="mt--6">
      <b-row>
        <b-col lg="2" />
        <b-col lg="8">
          <div class="card-wrapper">
            <!-- Input groups -->
            <card>
              <!-- Card header -->
              <h3 slot="header" class="mb-0">Soporte</h3>
              <span slot="header" class="f12x">Debe indicar un número y un correo de contacto</span>
              <!-- Card body -->
              <form>
                <!-- Input groups with icon -->
                <b-row>
                  <b-col md="12">
                    <base-input label="Nombre" prepend-icon="fas fa-user"></base-input>
                  </b-col>
                  <b-col md="12">
                    <base-input label="Teléfono" prepend-icon="fas fa-user"></base-input>
                  </b-col>
                  <b-col md="12">
                    <base-input label="Correo" prepend-icon="fas fa-user"></base-input>
                  </b-col>
                  <b-col md="12">
                    <base-input label="Persona de contacto" prepend-icon="fas fa-user"></base-input>
                  </b-col>
                  <b-col lg="12" class="text-center mt-4">
                    <b-textarea cols="30" rows="10" placeholder="Incidencia" class=""></b-textarea>
                  </b-col>
                  <b-col lg="12" class="text-center mt-4">
                    <button class="btn primary mt-3">
                      Envio
                    </button>
                  </b-col>
                </b-row>
              </form>
            </card>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { Select, Option } from 'element-ui'
  export default {
    name: 'form-components',
    components: {
      [Select.name]: Select,
      [Option.name]: Option
    },
    data() {
      return {
        modulos: [
          {titulo: 'Dashboards', checked: false, subtitulo: [{titulo: 'Dashboard', checked: false}, {titulo: 'Detallado', checked: false}]},
          {titulo: 'Tickets', checked: false, subtitulo: [{titulo: 'Agregar ticket', checked: false}, {titulo: 'Listado tickets', checked: false}]},
          {titulo: 'IoT Redes sociales', checked: true},
          {titulo: 'Encuestas', checked: false, subtitulo: [{titulo: 'Creación de encuestas', checked: false}, {titulo: 'Listado de encuestas', checked: false}, {titulo: 'Gráfico de encuestas', checked: false}]},
          {titulo: 'Promociones', checked: true, subtitulo: [{titulo: 'Creación de promociones', checked: false}, {titulo: 'Listado de promociones', checked: false}, {titulo: 'Gráfico de promociones', checked: false}]},
          {titulo: 'Facturación', checked: true, subtitulo: [{titulo: 'Agregar factura', checked: false}, {titulo: 'Listado de factura', checked: false}]},
          {titulo: 'IoT Cliente', checked: true},
          {titulo: 'Comercio', checked: false},
          {titulo: 'Control usuarios', checked: true}
        ],
        opcion1: [],
        opcion2: [],
        opcion3: [],
        opcion4: []
      }
    },
    mounted () {
    },
    methods: {
      pulsaModulo(modulo) {
        // console.log(modulo)
      }
    }
  }
</script>
<style scoped>
.inlineBlock{
  display: inline-block;
}

.W80{
  width: 80%;
}

.W90{
  width: 90%;
}

.nBorderB{
  border-bottom: none;
}

.f12x{
  font-size:12px;
}
</style>