<template>
  <div>
    <base-header class="pb-6" style="background-color:#0bdeea !important;">
    </base-header>

    <b-container fluid class="mt--6">
      <b-row>
        <b-col lg="2" />
        <b-col lg="8">
          <div class="card-wrapper">
            <!-- Input groups -->
            <card>
              <!-- Card header -->
              <h3 slot="header" class="mb-0">Licencia</h3>
              <!-- Card body -->
               <card class="W90 mAuto">
                    <b-row>
                        <b-col cols="6" class=""><b>Licencia de comercio</b></b-col>
                        <b-col cols="6" class="text-center"><b>19,99€</b></b-col>
                        <b-col cols="6" class="mt-2"><b>Pago mensual</b></b-col>
                    </b-row>
               </card>
               <h3 class="font-weight-bold mt-6">Módulos contratados</h3>
               <b-row class="mt-3">
                <b-col cols="12" md="4" class="mb-3" @click="desactivaCheck('dashboard')">
                    <card class="W90 mAuto" :class="dashboard ? 'moduloActivo' : 'moduloNoActivo'">
                        <b-row @click="dashboard ? dashboard = false : dashboard = true">
                            <b-col cols="12" class="text-center"><b>Dashboard</b></b-col>
                        </b-row>
                    </card>
                </b-col>
                <b-col cols="12" md="4" class="mb-3" @click="desactivaCheck('facturacion')">
                    <card class="W90 mAuto" :class="facturacion ? 'moduloActivo' : 'moduloNoActivo'">
                        <b-row class="">
                            <b-col cols="12" class="text-center"><b>Facturación</b></b-col>
                        </b-row>
                    </card>
                </b-col>
                <b-col cols="12" md="4" class="mb-3" @click="desactivaCheck('promociones')">
                    <card class="W90 mAuto" :class="promociones ? 'moduloActivo' : 'moduloNoActivo'">
                        <b-row @click="promociones ? promociones = false : promociones = true">
                            <b-col cols="12" class="text-center"><b>Promociones</b></b-col>
                        </b-row>
                    </card>
                </b-col>
                <b-col cols="12" md="4" class="mb-3" @click="desactivaCheck('gestoria')">
                  <card class="W90 mAuto" :class="gestoria ? 'moduloActivo' : 'moduloNoActivo'">
                      <b-row class="" @click="gestoria ? gestoria = false : gestoria = true">
                          <b-col cols="12" class="text-center"><b>Gestoria</b></b-col>
                      </b-row>
                  </card>
                </b-col>
                <b-col cols="12" md="4" class="mb-3" @click="desactivaCheck('encuestas')">
                  <card class="W90 mAuto" :class="encuestas ? 'moduloActivo' : 'moduloNoActivo'">
                      <b-row class="" @click="encuestas ? encuestas = false : encuestas = true">
                          <b-col cols="12" class="text-center"><b>Encuestas</b></b-col>
                      </b-row>
                  </card>
                </b-col>
                <b-col cols="12" md="4" class="mb-3" @click="desactivaCheck('gasto')">
                  <card class="W90 mAuto" :class="gasto ? 'moduloActivo' : 'moduloNoActivo'">
                      <b-row class="" @click="gasto ? gasto = false : gasto = true">
                          <b-col cols="12" class="text-center"><b>Gasto</b></b-col>
                      </b-row>
                  </card>
                </b-col>
               </b-row>
               <h3 class="font-weight-bold mt-3">Métodos de pago</h3>
            </card>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
  import { Select, Option } from 'element-ui'
  import "flatpickr/dist/flatpickr.css";
  import { mapActions, mapGetters } from 'vuex'

  export default {
    name: 'form-components',
    components: {
      [Select.name]: Select,
      [Option.name]: Option,
    },
    data() {
      return {
        dashboard2: true,
        facturacion2: false,
        promociones2: false,
        gasto2: false,
        nombrePersona: '',
        fecha: null,
        coste: 0,
        // selectMetodo: null,
        concepto: null,
        hour: null,
        idDoti: null,
        selectMetodo: [
          {
            label: 'Comercial',
            value: 'Tarjeta'
          },
          {
            label: 'Cajero',
            value: 'Bizum'
          },
          {
            label: 'Responsable',
            value: 'Metálico'
          },
        ],
      }
    },

    computed: {
      ...mapGetters({
        facturacion: 'facturacion',
        promociones: 'promociones',
        gasto: 'gasto',
        encuestas: 'encuestas',
        gestoria: 'gestoria',
        dashboard: 'dashboard'
      })
    },

    mounted () {
        this.fecha = new Date();
    },
    methods: {
      ...mapActions({
        setFacturacion: 'setFacturacion',
        setPromociones: 'setPromociones',
        setGasto: 'setGasto',
        setEncuestas: 'setEncuestas',
        setDashboard: 'setDashboard',
        setGestoria: 'setGestoria'
      }),
      guardaTicket() {
        let horaReal = this.hour.split(':')
        horaReal = horaReal[0] + ':' + horaReal[1]
        const data = {
            nombre: this.nombrePersona,
            fecha: this.fecha,
            coste: this.coste,
            hora: horaReal,
            concepto: this.concepto,
            idDoti: this.idDoti,
            metodo: this.metodo
        }
        let tickets = JSON.parse(localStorage.getItem('tickets') || "[]")
        tickets.push(data)
        localStorage.setItem("tickets", JSON.stringify(tickets))
      },

      desactivaCheck (modulo) {
        if (modulo === 'facturacion') { this.facturacion == true ? this.setFacturacion(false) : this.setFacturacion(true) }
        if (modulo === 'dashboard') { this.dashboard == true ? this.setDashboard(false) : this.setDashboard(true) }
        if (modulo === 'promociones') { this.promociones == true ? this.setPromociones(false) : this.setPromociones(true) }
        if (modulo === 'gasto') { this.gasto == true ? this.setGasto(false) : this.setGasto(true) }
        if (modulo === 'encuestas') { this.encuestas == true ? this.setEncuestas(false) : this.setEncuestas(true) }
        if (modulo === 'gestoria') { this.gestoria == true ? this.setGestoria(false) : this.setGestoria(true) }
      }
    }
  }
</script>
<style scoped>
.W90 {
    width:90%;
}

.mAuto{
    margin:0 auto;
}

.opa25{
    opacity: 0.25;
}

.moduloActivo{
  background: #00e0ec;
}

.moduloNoActivo{
  background: #e5fcfd;
}
</style>