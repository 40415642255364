<template>
  <div>
   <base-header class="pb-6" style="background-color:#0bdeea !important;">
    </base-header>

    <b-container fluid class="mt--6">
      <b-row>
        <b-col lg="2" />
        <b-col lg="8">
          <div class="card-wrapper">
            <card>
              <h3 slot="header" class="mb-0">Perfil</h3>
              <form>
                <b-row>
                  <b-col md="12">
                    <label class="form-control-label">Logo</label>
                    <b-form-file
                      v-model="archivo"
                      :state="Boolean(file1)"
                      placeholder="Elige el fichero y arrastralo..."
                      drop-placeholder="Arrastralo aquí..."
                      style="margin-bottom: 1.5rem;"
                      label="Foto"
                    ></b-form-file>
                  </b-col>
                  <b-col md="12">
                    <base-input label="Nombre social" prepend-icon="fas fa-user"></base-input>
                  </b-col>
                  <b-col md="12">
                    <base-input label="NIF" prepend-icon="fas fa-user"></base-input>
                  </b-col>
                  <b-col md="12">
                    <base-input label="Teléfono" prepend-icon="fas fa-user"></base-input>
                  </b-col>
                  <b-col md="12">
                    <base-input label="Dirección" prepend-icon="fas fa-user"></base-input>
                  </b-col>
                  <b-col md="12">
                    <base-input label="Actividad">
                      <el-select v-model="metodo" filterable
                                 placeholder="">
                        <el-option v-for="option in selectMetodo"
                                   :key="option.label"
                                   :label="option.label"
                                   :value="option.value">
                        </el-option>
                      </el-select>
                    </base-input>
                  </b-col>
                  <b-col md="6" style="text-align:left;">
                  </b-col>
                  <b-col md="6" style="text-align:right;">
                    <base-button @click.prevent="guardaTicket" type="primary" native-type="submit">Guardar</base-button>
                  </b-col>
                </b-row>
              </form>
            </card>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
  import { Select, Option } from 'element-ui'
  import "flatpickr/dist/flatpickr.css";

  export default {
    name: 'form-components',
    components: {
      [Select.name]: Select,
      [Option.name]: Option,
    },
    data() {
      return {
        nombrePersona: '',
        archivo: null,
        metodo: null,
        fecha: null,
        coste: 0,
        concepto: null,
        hour: null,
        idDoti: null,
        selectMetodo: [
          {
            label: 'Comercial',
            value: 'Tarjeta'
          },
          {
            label: 'Cajero',
            value: 'Bizum'
          },
          {
            label: 'Responsable',
            value: 'Metálico'
          },
        ],
      }
    },
    mounted () {
        this.fecha = new Date();
    },
    methods: {
      guardaTicket() {
        let horaReal = this.hour.split(':')
        horaReal = horaReal[0] + ':' + horaReal[1]
        const data = {
            nombre: this.nombrePersona,
            fecha: this.fecha,
            coste: this.coste,
            hora: horaReal,
            concepto: this.concepto,
            idDoti: this.idDoti,
            metodo: this.metodo
        }
        let tickets = JSON.parse(localStorage.getItem('tickets') || "[]")
        tickets.push(data)
        localStorage.setItem("tickets", JSON.stringify(tickets))
      }
    }
  }
</script>
